/*
 * @Author: wyq
 * @Date: 2021-05-18 10:17:34
 * @LastEditTime: 2021-09-14 15:35:21
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\http\service.js
 */
import http from './axios';

const baseUrl = '/v1'
// 登录
function login(params) {
  return http.post(`${baseUrl}/user/login`, params)
}
// 注册
function register(params) {
  return http.post(`${baseUrl}/user/register`, params)
}
// 获取知识点分类
function getKnowledgePoints(params) {
  return http.post(`${baseUrl}/knowledgePoints`, params)
}
// 获取题目列表
function getQuestionList(params) {
  return http.post(`${baseUrl}/questionBank/search`, params)
}
// 添加试题
function addQuestion(params) {
  return http.post(`${baseUrl}/questionBank/addQuestion`, params)
}
// 添加同源题
function addGroupQuestion(params) {
  return http.post(`${baseUrl}/questionBank/addGroupQuestion`, params)
}
// 获取全部同源题信息
function getQuestionInfo(params) {
  return http.post(`${baseUrl}/questionBank/getQuestionInfo`, params)
}
// 获取全部同源题编辑历史
function getQuestionHistory(params) {
  return http.post(`${baseUrl}/questionBank/getQuestionHistory`, params)
}
// 修改题干基础信息
function updateQuestionBase(params) {
  return http.post(`${baseUrl}/questionBank/updateQuestionBase`, params)
}
// 获取题目相关信息
function getQuestionSingleInfo(params) {
  return http.post(`${baseUrl}/questionBank/getQuestionSingleInfo`, params)
}
// 获取题目相关信息
function updateQuestion(params) {
  return http.post(`${baseUrl}/questionBank/updateQuestion`, params)
}
// 删除母题
function deleteQuestion(params) {
  return http.post(`${baseUrl}/questionBank/deleteQuestion`, params)
}
// 删除同源题
function deleteGroupQuestion(params) {
  return http.post(`${baseUrl}/questionBank/deleteGroupQuestion`, params)
}
// 更改题目审核状态
function updateQuestionCheck(params) {
  return http.post(`${baseUrl}/questionBank/updateQuestionCheck`, params)
}
// 更改题目审核状态
function getUserList() {
  return http.get(`${baseUrl}/questionBank/getUserList`)
}

// 上传图片
function uploadPhoto(params) {
  return http.post(`${baseUrl}/photo/upload`, params)
}

// cat创建图谱
function createKGLabel(params) {
  return http.post(`${baseUrl}/catKG/createLabel`, params)
}
// cat获取体系列表
function getKGList(params) {
  return http.post(`${baseUrl}/catKG/list`, params)
}
// cat获取树
function getKGTree(params) {
  return http.post(`${baseUrl}/catKG/tree`, params)
}
// cat更新图谱
function updateKGLabel(params) {
  return http.post(`${baseUrl}/catKG/updateLabel`, params)
}
// cat删除图谱
function deleteKGLabel(params) {
  return http.post(`${baseUrl}/catKG/deleteLabel`, params)
}

// 教材创建图谱
function createKPLabel(params) {
  return http.post(`${baseUrl}/knowledgePoints/createLabel`, params)
}
// 教材获取体系列表
function getKPList(params) {
  return http.post(`${baseUrl}/knowledgePoints/list`, params)
}
// 教材获取树
function getKPTree(params) {
  return http.post(`${baseUrl}/knowledgePoints/tree`, params)
}
// 教材更新图谱
function updateKPLabel(params) {
  return http.post(`${baseUrl}/knowledgePoints/updateLabel`, params)
}
// 教材删除图谱
function deleteKPLabel(params) {
  return http.post(`${baseUrl}/knowledgePoints/deleteLabel`, params)
}
// 教材试题分类列表
function getCategoryList(params) {
  return http.post(`${baseUrl}/knowledgePoints/questionCategoryList`, params)
}
// 创建教材试题分类
function createCategory(params) {
  return http.post(`${baseUrl}/knowledgePoints/createCategory`, params)
}
// 更新教材试题分类
function updateCategory(params) {
  return http.post(`${baseUrl}/knowledgePoints/updateCategory`, params)
}
// 更新教材试题分类
function deleteCategory(params) {
  return http.post(`${baseUrl}/knowledgePoints/deleteCategory`, params)
}


// 创建视频标签
function createVideoLabel(params) {
  return http.post(`${baseUrl}/video/createLabel`, params)
}
// 获取视频标签列表
function videoGraphList() {
  return http.post(`${baseUrl}/video/videoGraphList`)
}
// 更新视频标签
function updateVideoLabel(params) {
  return http.post(`${baseUrl}/video/updateLabel`, params)
}
// 删除视频标签
function deleteVideoLabel(params) {
  return http.post(`${baseUrl}/video/deleteLabel`, params)
}
// 添加视频
function addVideo(params) {
  return http.post(`${baseUrl}/video/addVideo`, params)
}
// 更新视频
function updateVideo(params) {
  return http.post(`${baseUrl}/video/updateVideo`, params)
}
// 搜索视频
function searchVideo(params) {
  return http.post(`${baseUrl}/video/searchVideo`, params)
}
// 删除视频
function delVideo(params) {
  return http.post(`${baseUrl}/video/delVideo`, params)
}
// 获取视频详情
function getVideoDetail(params) {
  return http.post(`${baseUrl}/video/getVideoDetail`,params)
}
// 更新视频审核状态
function updateVideoStatus(params) {
  return http.post(`${baseUrl}/video/updateVideoStatus`,params)
}
// 添加题目视频关联
function addQuestionVideoRelation(params) {
  return http.post(`${baseUrl}/video/addQuestionVideoRelation`,params)
}
// 获取题目视频关联列表
function getQuestionVideoRelationList(params) {
  return http.post(`${baseUrl}/video/getQuestionVideoRelationList`,params)
}
// 删除题目视频关联
function delQuestionVideoRelation(params) {
  return http.post(`${baseUrl}/video/delQuestionVideoRelation`,params)
}
// 刷新视频
function flushVideo(params) {
  return http.post(`${baseUrl}/video/flushVideo`,params)
}
export default {
  login,
  register,
  getKnowledgePoints,
  getQuestionList,
  addQuestion,
  addGroupQuestion,
  getQuestionInfo,
  getQuestionHistory,
  updateQuestionBase,
  getQuestionSingleInfo,
  updateQuestion,
  deleteQuestion,
  deleteGroupQuestion,
  updateQuestionCheck,
  getUserList,
  uploadPhoto,

  createKGLabel,
  getKGList,
  getKGTree,
  updateKGLabel,
  deleteKGLabel,

  createKPLabel,
  getKPList,
  getKPTree,
  updateKPLabel,
  deleteKPLabel,
  getCategoryList,
  createCategory,
  updateCategory,
  deleteCategory,

  createVideoLabel,
  videoGraphList, 
  updateVideoLabel, 
  deleteVideoLabel,
  addVideo,
  updateVideo,
  searchVideo,
  delVideo,
  getVideoDetail,
  updateVideoStatus,
  addQuestionVideoRelation,
  getQuestionVideoRelationList,
  delQuestionVideoRelation,
  flushVideo,
}

