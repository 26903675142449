/*
 * @Author: wyq
 * @Date: 2021-06-28 10:49:06
 * @LastEditTime: 2021-07-06 15:15:00
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\mathKeyboard\img.js
 */
export default {
  zkh: require('../../assets/images/keyboard/(.png'),
  ykh: require('../../assets/images/keyboard/).png'),
  zfkh: require('../../assets/images/keyboard/[.png'),
  yfkh: require('../../assets/images/keyboard/].png'),
  abs: require('../../assets/images/keyboard/abs.png'),
  back: require('../../assets/images/keyboard/back.png'),
  dback: require('../../assets/images/keyboard/dback.png'),
  cuadrado: require('../../assets/images/keyboard/cuadrado.png'),
  dshift: require('../../assets/images/keyboard/dshift.png'),
  fraccion: require('../../assets/images/keyboard/fraccion.png'),
  keyboard: require('../../assets/images/keyboard/keyboard.png'),
  potencia: require('../../assets/images/keyboard/potencia.png'),
  raiz: require('../../assets/images/keyboard/raiz.png'),
  raiz2: require('../../assets/images/keyboard/raiz2.png'),
  shift: require('../../assets/images/keyboard/shift.png'),
  tercera: require('../../assets/images/keyboard/tercera.png'),
  write: require('../../assets/images/keyboard/write.png'),
  write_no: require('../../assets/images/keyboard/write-no.png'),
  keyboard_no: require('../../assets/images/keyboard/keyboard-no.png'),
  penicon: require('../../assets/images/keyboard/penicon.png'),
  jiaobiao: require('../../assets/images/keyboard/jiaobiao.png'),
  infty: require('../../assets/images/keyboard/infty.png')
}