/*
 * @Author: wyq
 * @Date: 2021-06-21 15:32:51
 * @LastEditTime: 2021-10-21 16:12:57
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \question_bank_console\src\store\state.js
 */
const state = {
  gradeList: [{
    label: '初中',
    value: 1,
    classifyList: [
      {
        label: '例题',
        value: 1
      }, {
        label: '练习',
        value: 2
      }, {
        label: '习题',
        value: 3
      }, {
        label: '复习题',
        value: 4
      }
    ]
  }, {
    label: '高中',
    value: 2,
    classifyList: [
      {
        label: '例题',
        value: 1
      }, {
        label: '练习',
        value: 2
      }, {
        label: '习题',
        value: 3
      }, {
        label: '复习参考题',
        value: 4
      }
    ]
  }],
  subjectList: [{
    label: '数学',
    value: 2
  }],
  typeList: [{
    label: '单选题',
    value: 1
  }, {
    label: '填空题',
    value: 2
  }, {
    label: '解答题',
    value: 3
  }, {
    label: '多选题',
    value: 4
  }],
  questionBankList: [{
    label: '教材题库',
    value: 1
  }, {
    label: 'CAT测试',
    value: 2
  }],
  specialSubjectList: [{
    label: '集合',
    value: 1
  }, {
    label: '数列',
    value: 2
  }, {
    label: '排列组合',
    value: 3
  }],
  progressTree: [{
    id: 1,
    label: '集合与常用逻辑用语',
  }, {
    id: 2,
    label: '一元二次函数、方程和不等式',
  }, {
    id: 3,
    label: '函数的概念与性质',
  }, {
    id: 4,
    label: '指数函数与对数函数',
  }, {
    id: 5,
    label: '三角函数',
  }, {
    id: 6,
    label: '平面向量及其应用',
  }, {
    id: 7,
    label: '复数',
  }, {
    id: 8,
    label: '立体几何初步',
  }, {
    id: 9,
    label: '统计',
  }, {
    id: 10,
    label: '概率',
  }, {
    id: 11,
    label: '空间向量与立体几何',
  }, {
    id: 12,
    label: '直线和圆的方程',
  }, {
    id: 13,
    label: '圆锥曲线的方程',
  }, {
    id: 14,
    label: '数列',
  }, {
    id: 15,
    label: '一元函数的导数及其应用',
  }, {
    id: 16,
    label: '计数原理',
  }, {
    id: 17,
    label: '随机变量及其分布',
  }, {
    id: 18,
    label: '成对数据的统计分析',
  },{
    id: 19,
    label: '有理数',
  }, {
    id: 20,
    label: '整式的加减',
  }, {
    id: 21,
    label: '一元一次方程',
  }, {
    id: 22,
    label: '几何图形初步',
  }, {
    id: 23,
    label: '相交线与平行线',
  }, {
    id: 24,
    label: '实数',
  }, {
    id: 25,
    label: '平面直角坐标系',
  }, {
    id: 26,
    label: '二元一次方程组',
  }, {
    id: 27,
    label: '不等式与不等式组',
  }, {
    id: 28,
    label: '数据的收集、整理与描述',
  }, {
    id: 29,
    label: '三角形',
  }, {
    id: 30,
    label: '全等三角形',
  }, {
    id: 31,
    label: '轴对称',
  }, {
    id: 32,
    label: '整式的乘法与因式分解',
  }, {
    id: 33,
    label: '分式',
  }, {
    id: 34,
    label: '二次根式',
  }, {
    id: 35,
    label: '勾股定理',
  }, {
    id: 36,
    label: '平行四边形',
  },{
    id: 37,
    label: '一次函数',
  },{
    id: 38,
    label: '数据的分析',
  },{
    id: 39,
    label: '一元二次方程',
  },{
    id: 40,
    label: '二次函数',
  },{
    id: 41,
    label: '旋转',
  },{
    id: 42,
    label: '圆',
  },{
    id: 43,
    label: '概率初步',
  },{
    id: 44,
    label: '反比例函数',
  },{
    id: 45,
    label: '相似',
  },{
    id: 46,
    label: '锐角三角函数',
  },{
    id: 47,
    label: '投影与视图',
  },],
  progressTree2: [{
    id: 1,
    label: '集合与常用逻辑用语',
  }, {
    id: 2,
    label: '一元二次函数、方程和不等式',
  }, {
    id: 3,
    label: '函数的概念与性质',
  }, {
    id: 4,
    label: '指数函数与对数函数',
  }, {
    id: 5,
    label: '三角函数',
  }, {
    id: 6,
    label: '平面向量及其应用',
  }, {
    id: 7,
    label: '复数',
  }, {
    id: 8,
    label: '立体几何初步',
  }, {
    id: 9,
    label: '统计',
  }, {
    id: 10,
    label: '概率',
  }, {
    id: 11,
    label: '空间向量与立体几何',
  }, {
    id: 12,
    label: '直线和圆的方程',
  }, {
    id: 13,
    label: '圆锥曲线的方程',
  }, {
    id: 14,
    label: '数列',
  }, {
    id: 15,
    label: '一元函数的导数及其应用',
  }, {
    id: 16,
    label: '计数原理',
  }, {
    id: 17,
    label: '随机变量及其分布',
  }, {
    id: 18,
    label: '成对数据的统计分析',
  },],
  progressTree1: [{
    id: 19,
    label: '有理数',
  }, {
    id: 20,
    label: '整式的加减',
  }, {
    id: 21,
    label: '一元一次方程',
  }, {
    id: 22,
    label: '几何图形初步',
  }, {
    id: 23,
    label: '相交线与平行线',
  }, {
    id: 24,
    label: '实数',
  }, {
    id: 25,
    label: '平面直角坐标系',
  }, {
    id: 26,
    label: '二元一次方程组',
  }, {
    id: 27,
    label: '不等式与不等式组',
  }, {
    id: 28,
    label: '数据的收集、整理与描述',
  }, {
    id: 29,
    label: '三角形',
  }, {
    id: 30,
    label: '全等三角形',
  }, {
    id: 31,
    label: '轴对称',
  }, {
    id: 32,
    label: '整式的乘法与因式分解',
  }, {
    id: 33,
    label: '分式',
  }, {
    id: 34,
    label: '二次根式',
  }, {
    id: 35,
    label: '勾股定理',
  }, {
    id: 36,
    label: '平行四边形',
  },{
    id: 37,
    label: '一次函数',
  },{
    id: 38,
    label: '数据的分析',
  },{
    id: 39,
    label: '一元二次方程',
  },{
    id: 40,
    label: '二次函数',
  },{
    id: 41,
    label: '旋转',
  },{
    id: 42,
    label: '圆',
  },{
    id: 43,
    label: '概率初步',
  },{
    id: 44,
    label: '反比例函数',
  },{
    id: 45,
    label: '相似',
  },{
    id: 46,
    label: '锐角三角函数',
  },{
    id: 47,
    label: '投影与视图',
  },]
}
export default state