<!--
 * @Author: wyq
 * @Date: 2021-06-15 11:11:57
 * @LastEditTime: 2021-08-30 10:03:17
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\contentContainer.vue
-->
<template>
  <div class="content-container">
    <div class="title">
      <div v-if="title" class="default">{{title}}</div>
      <slot v-if="$slots.title" name="title"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
}
</script>
<style lang="scss" scoped>
.content-container {
  margin: 32px;
  padding: 0 25px;
  background-color: #fff;
  overflow: hidden;
  .title {
    height: 54px;
    line-height: 54px;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    .default {
      display: inline-block;
      font-family: PingFangSC-Medium;
    }
  }
}
</style>