<!--
 * @Author: wyq
 * @Date: 2021-07-09 10:59:49
 * @LastEditTime: 2021-07-09 11:21:30
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\preview\labelItem.vue
-->
<template>
  <div class="label-item">
    <span class="label">{{label}}：</span>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: ['label'],
}
</script>
<style lang="scss" scoped>
.label-item {
  font-size: 14px;
  font-weight: 800;
  color: #144686;
  .label {
    margin-right: 4px;
    color: #666666;
    font-weight: 400;
  }
}
</style>