<!--
 * @Author: wyq
 * @Date: 2021-08-27 15:01:32
 * @LastEditTime: 2021-08-27 16:31:14
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\pageContainer.vue
-->
<template>
  <el-container class="page-container">
    <el-aside v-if="$slots.aside" width="360px">
      <el-scrollbar style="height:100%">
        <div class="aside-title">
          {{asideTitle}}
          <div class="aside-title-btn">
            <slot name="asideTitle"></slot>
          </div>
        </div>
        <slot name="aside"></slot>
      </el-scrollbar>
    </el-aside>
    <el-main>
      <el-scrollbar style="height:100%">
        <slot></slot>
      </el-scrollbar>
    </el-main>
  </el-container>
</template>
<script>
export default {
  props: {
    asideTitle: String,
  },
}
</script>
<style lang="scss" scoped>
.page-container {
  .aside-title {
    position: relative;
    padding: 20px 32px;
    margin-top: 32px;
    line-height: 16px;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    &::before {
      display: inline-block;
      width: 6px;
      height: 16px;
      margin-right: 12px;
      background: #144686;
      content: '';
      vertical-align: top;
    }
    &-btn {
      position: absolute;
      top: 0;
      right: 32px;
      display: flex;
      height: 100%;
    }
  }
}
</style>