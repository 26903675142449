/*
 * @Author: wyq
 * @Date: 2021-06-21 15:32:29
 * @LastEditTime: 2021-06-21 15:44:14
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \question_bank_console\src\store\actions.js
 */
const actions = {

}
export default actions