/*
 * @Author: wyq
 * @Date: 2021-06-15 09:43:10
 * @LastEditTime: 2021-06-15 09:47:20
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \question_bank_console\src\utils\katex.js
 */

// 引入katex下的自动渲染函数
import renderMathInElement from 'katex/contrib/auto-render/auto-render'

// 定义自动渲染的配置参数,这些参数根据你的需求进行修改，下面的参数是官网上抄下来的
const renderOption = {
  delimiters: [
    { left: '$$', right: '$$', display: true },
    { left: '$', right: '$', display: false },
    { left: '\\(', right: '\\)', display: false },
    { left: '\\[', right: '\\]', display: true }
  ],
  throwOnError: false
}

function formula(dom) {
  renderMathInElement(dom, renderOption)
}

export default formula