<!--
 * @Author: wyq
 * @Date: 2021-07-09 09:44:51
 * @LastEditTime: 2021-09-18 10:51:31
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\components\preview\previewDialog.vue
-->
<template>
  <div v-if="visiable" class="preview-dialog">
    <el-button class="close" icon="el-icon-close" circle @click="close"></el-button>
    <div class="preview-dialog-container">
      <div v-if="questionLabel.grade" class="preview-dialog-header">
        <el-row>
          <el-col :span="6">
            <label-item label="难度">{{ questionLabel.difficultyLevel }}</label-item>
          </el-col>
          <el-col :span="6">
            <label-item label="区分度">{{ questionLabel.discrimination }}</label-item>
          </el-col>
          <el-col :span="6">
            <label-item label="猜测因子">{{ questionLabel.guessing }}</label-item>
          </el-col>
          <el-col :span="6">
            <label-item label="推荐答题时间">{{ questionLabel.ansSecond }}秒</label-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <label-item label="年级">{{ questionLabel.grade }}</label-item>
          </el-col>
          <el-col :span="6">
            <label-item label="学科">{{ questionLabel.subject }}</label-item>
          </el-col>
          <el-col :span="6">
            <label-item label="所属题库">{{ questionLabel.questionBank }}</label-item>
          </el-col>
          <el-col :span="6" v-if="questionBank == 1">
            <label-item label="教材试题分类">
              <el-select v-model="questionLabel.classify" disabled size="mini">
                <el-option
                  v-for="item in classifyList"
                  :key="'versionList' + item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </label-item>
          </el-col>
        </el-row>
        <el-row v-if="questionBank == 1">
          <el-col :span="6">
            <label-item label="教材版本">
              <!-- <span v-if="questionLabel.chapter">{{questionLabel.knowledgePoints}}</span> -->
              <el-select v-model="questionLabel.version" disabled size="mini">
                <el-option
                  v-for="item in versionList"
                  :key="'versionList' + item.id"
                  :label="item.label"
                  :value="item.id"
                ></el-option>
              </el-select>
            </label-item>
          </el-col>
          <el-col :span="12">
            <label-item label="知识点分类">
              <!-- <span v-if="questionLabel.chapter">{{questionLabel.knowledgePoints}}</span> -->
              <el-cascader
                v-model="questionLabel.knowledgePoints"
                :options="knowledgePointsList"
                :props="cascaderProps"
                disabled
                size="mini"
              ></el-cascader>
            </label-item>
          </el-col>
        </el-row>
      </div>
      <div class="preview-dialog-body" ref="katex">
        <div v-if="!questionLabel.grade" class="preview-dialog-aside">
          <div class="body">
            <p>客户端</p>
            <p>功能区</p>
          </div>
        </div>
        <div v-else class="preview-dialog-aside">
          <div v-if="userType == 1" class="header">
            <el-form label-position="top" size="mini">
              <el-form-item label="管理员审核">
                <el-select v-model="checkForm.checkType" placeholder="未审核">
                  <el-option label="通过" :value="2"></el-option>
                  <el-option label="不通过" :value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="questionBank == 2 && checkForm.checkType == 2" label="发布状态">
                <el-select v-model="checkForm.releaseStatus" placeholder="未发布">
                  <el-option label="未发布" :value="0"></el-option>
                  <el-option label="CAT测试题" :value="1"></el-option>
                  <el-option label="CAT训练题" :value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="checkForm.checkType == 3" label="原因">
                <el-input type="textarea" v-model="checkForm.checkContent" :rows="4" resize="none"></el-input>
              </el-form-item>
              <el-form-item class="btn-box">
                <el-button
                  type="success"
                  size="medium"
                  @click="submitCheckForm"
                  :disabled="!checkForm.checkType"
                >提交</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="body">
            <p>当前审核状态</p>
            <div v-if="questionForm.checkType == 3" class="status-circular warning">审核未通过</div>
            <div v-else-if="questionForm.checkType == 2" class="status-circular success">审核通过</div>
            <div v-else class="status-circular disabled">待审核</div>
            <div
              v-if="userType != 1 && questionForm.checkContent"
              class="reason"
            >原因：{{ questionForm.checkContent }}</div>
            <template v-if="questionBank == 2">
              <p>当前发布状态</p>
              <div v-if="questionForm.releaseStatus == 0" class="status-circular warning">未发布</div>
              <div
                v-else-if="questionForm.releaseStatus == 1"
                class="status-circular success"
              >CAT测试题</div>
              <div
                v-else-if="questionForm.releaseStatus == 2"
                class="status-circular success"
              >CAT训练题</div>
            </template>
          </div>
        </div>
        <div class="preview-dialog-main">
          <el-scrollbar style="height: 100%;">
            <div class="single-choice-question question-preview">
              <div class="preview-topic">
                <div class="title">{{ questionForm.title }}</div>
                <div v-for="item in questionForm.titleImages" :key="item.url" class="image">
                  <img :src="item.url" />
                  <p>{{ item.label }}</p>
                </div>
                <template v-if="questionForm?.titleHjyPhoto?.length">
                  <p>海经院图片</p>
                  <div v-for="item in questionForm.titleHjyPhoto" :key="item.url" class="image">
                    <img :src="item.url" />
                    <p>{{ item.label }}</p>
                  </div>
                </template>
                <template v-if="(questionType == 1 || questionType == 4) && questionForm.options">
                  <div v-for="(item, index) in questionForm.options" :key="index" class="option">
                    <span class="option-label">{{ String.fromCharCode(64 + parseInt(index + 1)) }}</span>
                    <span>{{ item.value }}</span>
                    <img v-for="i in item.imageUrl" :key="i.label" :src="i.url" />
                  </div>
                </template>
              </div>
              <div class="preview-analyze">
                <div v-if="questionForm.answer">
                  [正确答案]
                  <span
                    v-if="questionType == 1"
                  >{{ String.fromCharCode(64 + parseInt(questionForm.answer) + 1) }}</span>
                  <span v-else-if="questionType == 2">${{ questionForm.answer }}$</span>
                  <template v-else-if="questionType == 4">
                    <span
                      v-for="answerItem in questionForm.arrAnswer"
                      :key="'answer' + answerItem"
                    >{{ String.fromCharCode(64 + parseInt(answerItem) + 1) }}</span>
                  </template>
                </div>
                <div
                  v-if="questionForm.answerAnalysis || (questionForm.answerAnalysisImages && questionForm.answerAnalysisImages.length)"
                >[分析] {{ questionForm.answerAnalysis }}</div>
                <div
                  v-for="item in questionForm.answerAnalysisImages"
                  :key="item.url"
                  class="image"
                >
                  <img :src="item.url" />
                  <p>{{ item.label }}</p>
                </div>
                <div>[解答] {{ questionForm.answerProcess }}</div>
                <div v-for="item in questionForm.answerProcessImages" :key="item.url" class="image">
                  <img :src="item.url" />
                  <p>{{ item.label }}</p>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import labelItem from './labelItem.vue'
export default {
  props: {
    grade: Number,
    subject: Number,
    id: Number,
    visiable: Boolean,
    form: Object,
    type: Number,
  },
  components: {
    labelItem,
  },
  data() {
    return {
      questionType: 1,
      questionBank: 1,
      questionForm: {},
      questionLabel: {},
      checkForm: {
        checkType: null,
        checkContent: null,
        releaseStatus: 0,
      },

      versionList: [],
      knowledgePointsList: [],
      classifyList: [],
      cascaderProps: {
        value: 'id',
        label: 'label',
      },
    }
  },
  computed: {
    userType() {
      return localStorage.getItem('userType')
    },
  },
  watch: {
    visiable(val) {
      if (val) {
        if (this.grade && this.subject && this.id) {
          this.getQuestionSingleInfo()
        }
        if (this.form) {
          this.questionType = this.type
          this.questionForm = this.form
          setTimeout(() => {
            this.$formula(this.$refs['katex'])
          }, 100)
        }
      }
    },
  },
  methods: {
    getQuestionSingleInfo() {
      let params = {
        grade: this.grade,
        subject: this.subject,
        questionId: this.id,
      }
      this.$service.getQuestionSingleInfo(params).then((res) => {
        this.questionType = res.type
        this.questionBank = res.questionBank
        // 格式化数据
        this.questionForm = this.$formattedData(res)

        setTimeout(() => {
          this.$formula(this.$refs['katex'])
        }, 500)

        const [version, ...knowledgePoints] = res.knowledgePoints

        // 其他信息格式化
        let questionLabel = {
          difficultyLevel: res.difficultyLevel,
          discrimination: res.discrimination,
          guessing: res.guessing,
          ansSecond: res.ansSecond,
          classify: res.classify,
          version,
          knowledgePoints,
        }
        const nameLabel = ['grade', 'subject', 'questionBank']
        for (const item of nameLabel) {
          let current = this.$store.state[`${item}List`].find(
            (i) => i.value == res[item]
          )
          questionLabel[item] = current ? current.label : '-'
        }

        this.questionLabel = questionLabel
        if (this.questionBank == 1) {
          this.getVersionList()
        }
        if (this.userType == 1) {
          this.checkForm.checkType =
            res.checkType != 1 && res.checkType != 4 ? res.checkType : null
          this.checkForm.checkContent = res.checkContent
          this.checkForm.releaseStatus = res.releaseStatus
        }
      })
    },
    close() {
      this.$emit('update:visiable', false)
    },
    submitCheckForm() {
      let params = {
        grade: this.grade,
        subject: this.subject,
        questionId: this.id,
        checkType: this.checkForm.checkType,
        checkContent:
          this.checkForm.checkType == 3 ? this.checkForm.checkContent : '',
        releaseStatus:
          this.questionBank == 2 && this.checkForm.checkType == 2
            ? this.checkForm.releaseStatus
            : 0,
      }
      this.$service.updateQuestionCheck(params).then(() => {
        this.$message({
          message: '提交成功！',
          type: 'success',
        })
        this.getQuestionSingleInfo()
      })
    },
    // 获取教材版本列表
    getVersionList() {
      let params = {
        grade: this.grade,
        subject: this.subject,
      }
      this.$service.getKPList(params).then((res) => {
        this.versionList = res.book
        if (this.questionLabel.knowledgePoints.length) {
          let knowledgePoints = this.questionLabel.knowledgePoints
          const index = knowledgePoints.indexOf(0)
          if (index > -1) {
            knowledgePoints = knowledgePoints.slice(0, index)
          }
          console.log(knowledgePoints)
          this.questionLabel.knowledgePoints = knowledgePoints
          this.getKnowledgePointsList()
          this.getCategoryList()
        }
      })
    },
    // 获取教材版本列表
    getKnowledgePointsList() {
      let params = {
        id: this.questionLabel.version,
      }
      this.$service.getKPTree(params).then((res) => {
        this.knowledgePointsList = res.tree
      })
    },
    // 获取教材试题分类列表
    getCategoryList() {
      let params = {
        bookId: this.questionLabel.version,
      }
      this.$service.getCategoryList(params).then((res) => {
        this.classifyList = res.list
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.preview-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &-container {
    width: 1280px;
    margin: 20px auto;
  }
  &-header {
    padding: 15px 24px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #eee;
    .el-row + .el-row {
      margin-top: 14px;
    }
  }
  &-body {
    display: flex;
    height: 800px;
  }
  &-aside {
    display: flex;
    flex-direction: column;
    width: 256px;
    padding: 32px;
    background-color: #144686;
    color: #fff;
    font-size: 32px;
    box-sizing: border-box;
    .header {
      min-height: 300px;
      ::v-deep .el-form-item__label {
        color: #fff;
      }
      .el-select {
        width: 100%;
      }
      .btn-box {
        text-align: right;
      }
    }
    .body {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      ::v-deep .el-form-item__label {
        color: #fff;
      }
      & > p {
        font-size: 18px;
      }
      .status-circular {
        width: 94px;
        height: 94px;
        margin: 10px auto;
        border-radius: 50%;
        font-size: 16px;
        line-height: 94px;
        &.warning {
          background-color: #d90e0e;
        }
        &.success {
          background-color: #00ba95;
        }
        &.disabled {
          background-color: #d8d8d8;
        }
      }
      .reason {
        margin-top: 30px;
        font-size: 16px;
        text-align: left;
      }
    }
    .footer {
      font-size: 16px;
    }
  }
  &-main {
    flex: 1;
    background-color: #f4f6f8;
  }
  .question-preview {
    padding: 20px;
    .preview-topic {
      padding: 24px;
      box-shadow: 3px 6px 9px 0px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      background-color: #fff;
      .title {
        font-size: 26px;
        color: #333333;
        line-height: 37px;
        white-space: pre-wrap;
      }
      .option {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-top: 20px;
        border-radius: 8px;
        border: 1px solid rgba(59, 79, 115, 0.2);
        white-space: pre-wrap;
        img {
          width: 100px;
          height: auto;
        }
        .option-label {
          padding: 0 16px;
          margin-right: 10px;
          font-size: 24px;
          color: #333333;
          line-height: 24px;
          border-right: 1px solid #f0f0f0;
        }
      }
    }
    .preview-analyze {
      margin-top: 20px;
      padding: 0 24px 24px 24px;
      background: rgba(0, 152, 122, 0.2);
      border-radius: 16px;
      border: 1px solid #00ba95;
      white-space: pre-wrap;
      &::before {
        display: block;
        width: 139px;
        height: 65px;
        margin-left: 3px;
        background: #00ba95;
        border-radius: 0px 0px 4px 4px;
        font-size: 26px;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 65px;
        content: "题目解析";
      }
      & > div {
        line-height: 30px;
        &:first-of-type {
          margin-top: 24px;
        }
      }
    }

    .image {
      display: inline-block;
      width: 200px;
      margin: 10px 0;
      img {
        width: 100%;
        height: auto;
      }
      p {
        margin: 0;
        text-align: center;
      }
      & + .image {
        margin-left: 20px;
      }
    }
  }
}
</style>
