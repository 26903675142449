/*
 * @Author: wyq
 * @Date: 2021-06-08 11:18:12
 * @LastEditTime: 2021-08-27 15:04:40
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\main.js
 */
document.title = '试题录入系统'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import service from './http/service'
import moment from 'moment'

import ElementUI from 'element-ui';
import './assets/theme/index.css';
// import 'element-ui/lib/theme-chalk/index.css';

import 'katex/dist/katex.css'
import katex from './utils/katex'
import contentContainer from './components/contentContainer.vue'
import pageContainer from './components/pageContainer.vue'
import previewDialog from './components/preview/previewDialog.vue'

import store from './store'

import MathKeyboard from './components/mathKeyboard/index.js'

import VuevideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';

import * as filters from './utils/filters.js'
import { formattedData } from './utils/util'

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false
Vue.prototype.$service = service
Vue.prototype.$moment = moment
Vue.prototype.$formattedData = formattedData

Vue.use(ElementUI)
Vue.use(MathKeyboard)
Vue.prototype.$formula = katex
Vue.use(VuevideoPlayer);

Vue.component('contentContainer', contentContainer)
Vue.component('pageContainer', pageContainer)
Vue.component('previewDialog', previewDialog)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
