/*
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2021-09-10 10:24:28
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
	{
		path: '/',
		redirect: '/addNewQuestion'
	}, {
		path: "/login",
		name: "Login",
		component: () => import('../views/login'),
	}, {
		path: "/index",
		name: "Index",
		redirect: '/home',
		component: () => import('../views/index'),
		children: [
			{
				path: "/home",
				name: "Home",
				component: () => import('../views/home'),
			},
			{
				path: "/detail",
				name: "Detail",
				component: () => import('../views/detail'),
			},
			{
				path: "/questionBank",
				name: "QuestionBank",
				component: () => import('../views/questionBank'),
			},
			{
				path: "/addNewQuestion",
				name: "AddNewQuestion",
				component: () => import('../views/addNewQuestion'),
			},
			{
				path: "/addGroupQuestion",
				name: "AddGroupQuestion",
				component: () => import('../views/addGroupQuestion'),
			},
			{
				path: "/editQuestion",
				name: "EditQuestion",
				component: () => import('../views/editQuestion'),
			},
			{
				path: "/knowledgeGraph",
				name: "KnowledgeGraph",
				component: () => import('../views/knowledgeGraph'),
			},
			{
				path: "/knowledgePoint",
				name: "KnowledgePoint",
				component: () => import('../views/knowledgePoint'),
			},
			{
				path: "/videoGraph",
				name: "VideoGraph",
				component: () => import('../views/videoGraph'),
			},
			{
				path: "/videoBank",
				name: "VideoBank",
				component: () => import('../views/videoBank'),
			},
			{
				path: "/addVideo",
				name: "AddVideo",
				component: () => import('../views/addVideo'),
			},
			{
				path: "/videoDetail",
				name: "VideoDetail",
				component: () => import('../views/videoDetail'),
			},
		]
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop;
		} else {
			return { x: 0, y: 0 }
		}
	},
});

router.beforeEach((to, from, next) => {
	// 路由校验权限
	if (to.name != 'Login') {
		if (localStorage.getItem('token')) {
			next()
		} else {
			next({
				path: '/login'
			})
		}
	} else {
		next()
	}
})

Vue.use(VueRouter);

export default router;
