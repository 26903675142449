/*
 * @Author: wyq
 * @Date: 2021-07-06 16:56:12
 * @LastEditTime: 2021-07-06 17:02:00
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \question_bank_console\src\utils\filters.js
 */
import moment from 'moment'

function datefmt(input, fmtstring) {
  if (input) {
    return moment(input * 1000).format(fmtstring);
  } else {
    return "";
  }
}
function getQuestionType(val) {
  switch (val) {
    case 1:
      return '单选题'
    case 2:
      return '填空题'
    case 3:
      return '解答题'
    case 4:
      return '多选题'
  }
}
export {
  datefmt,
  getQuestionType
}