/*
 * @Author: wyq
 * @Date: 2021-06-21 15:32:42
 * @LastEditTime: 2021-06-21 15:44:29
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \question_bank_console\src\store\mutations.js
 */
const mutations = {

}
export default mutations