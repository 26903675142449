/*
 * @Author: wyq
 * @Date: 2021-07-15 11:02:54
 * @LastEditTime: 2021-09-17 10:40:35
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \question_bank_console\src\utils\util.js
 */
// 格式化数据
export function formattedData(res) {
  let form = {}
  const constantLabel = [
    'title',
    'optionNum',
    'answer',
    'answerProcess',
    'answerAnalysis',
    'answerAnalysis',
    'difficultyLevel',
    'ansSecond',
    'checkType',
    'checkContent',
    'releaseStatus',
    'discrimination',
    'guessing'
  ]

  for (const i of constantLabel) {
    form[i] = res[i]
  }
  if (form.optionNum == 0) {
    form.optionNum = 4
  }
  const imgLabel = ['title', 'answerProcess', 'answerAnalysis']
  for (const i of imgLabel) {
    console.log(`${i}Photo`, res[`${i}Photo`])
    form[`${i}Images`] = res[`${i}Photo`]
      ? res[`${i}Photo`].map((i) => {
        return {
          label: i.name,
          url: i.url,
        }
      })
      : []
  }
  form.titleHjyPhoto = res.titleHjyPhoto?.map(i => {
    return {
      label: i.name,
      url: i.url,
    }
  })
  if (res.type == 1 || res.type == 4) {
    form.options = []
    for (let i = 0; i < res.optionNum; i++) {
      form.options.push({
        value: res[`option${i + 1}`],
        imageUrl: res[`option${i + 1}Photo`]
          ? res[`option${i + 1}Photo`].map((i) => {
            return {
              label: i.name,
              url: i.url,
            }
          })
          : [],
      })
    }
  }
  if (res.type == 4) {
    form.arrAnswer = res.answer.split(',')
  }
  console.log(form)
  return form
}
