<!--
 * @Author: wyq
 * @Date: 2021-06-08 11:18:12
 * @LastEditTime: 2021-08-26 15:27:12
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\App.vue
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
@font-face {
  font-family: PingFangSC-Bold; /*这里是说明调用来的字体名字*/
  src: url('./assets/theme/fonts/HarmonyOS_Sans_SC_Black.ttf'); /*这里是字体文件路径*/
}
@font-face {
  font-family: PingFangSC-Medium; /*这里是说明调用来的字体名字*/
  src: url('./assets/theme/fonts/HarmonyOS_Sans_SC_Bold.ttf'); /*这里是字体文件路径*/
}
@font-face {
  font-family: PingFangSC-Normal; /*这里是说明调用来的字体名字*/
  src: url('./assets/theme/fonts/HarmonyOS_Sans_SC_Medium.ttf'); /*这里是字体文件路径*/
}
@font-face {
  font-family: PingFangSC-Regular; /*这里是说明调用来的字体名字*/
  src: url('./assets/theme/fonts/HarmonyOS_Sans_SC_Regular.ttf'); /*这里是字体文件路径*/
}
body {
  padding: 0;
  margin: 0;
  background-color: #f4f6f8;
}
#app {
  font-family: PingFangSC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  height: 100vh;
}
.el-pagination {
  margin-top: 60px;
  margin-bottom: 48px;
  text-align: center;
}
.el-message-box__btns {
  .el-button {
    width: 108px;
    height: 36px;
  }
  .el-button + .el-button {
    margin-left: 24px;
  }
}

.uploader {
  padding: 16px 16px 8px 16px;
  border: 1px dashed #d8d8d8;
}

.select-form {
	padding-top: 24px;
	padding-bottom: 24px;
	.el-form-item {
		padding: 0 32px;
	}
}
</style>
