/*
 * @Author: wyq
 * @Date: 2021-06-21 15:32:24
 * @LastEditTime: 2021-06-21 15:43:42
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \question_bank_console\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions
})