/*
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2021-07-20 17:24:22
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\http\axios.js
 */
import axios from 'axios';
import { Message } from 'element-ui'
import Router from '../router/index'

// 请求拦截
axios.interceptors.request.use(config => {
  config.headers.Authorization = localStorage.getItem('token')
  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(response => {
  let data = response.data;
  if (data.Code == 200) {
    return data.Data
  } else {
    Message({
      message: data.Msg,
      type: 'danger'
    });
    return Promise.reject(data)
  }
}, error => {
  // 请求失败
  if (error && error.response) {
    switch (error.response.status) {
      case 401:
        Router.replace({
          path: '/login'
        })
        break
      default:
        // 如果以上都不是的处理
        return Promise.reject(error);
    }
  }

})
export default axios