<template>
  <div class="keyboard" :class="{'keyboardup':show}" id="keyboard">
    <div class="keyboard-div" @click="keyhide()" v-show="show && bgshow"></div>
    <div class="keyboard-output">
      <span
        :id="mathinput"
        class="editor"
        @touchstart="cursorDown($event)"
        @touchmove="cursorMove($event)"
        @touchup="cursorUp($event)"
      ></span>
      <a @click="keyhide()">确定</a>
    </div>
    <div class="keyboard-panel" id="keyboard">
      <div class="keyboard-panel-default">
        <div class="keyboard-panel-symbol">
          <ul>
            <li v-for="item in keysym" @click="insertar(item.v)" :key="item.v">
              <img :src="item.i" v-if="item.i" />
              {{item.k}}
            </li>
          </ul>
        </div>
        <div class="keyboard-panel-num">
          <ul>
            <li v-for="item in keynum" :key="item.v" @click="insertar(item.v)">{{item.k}}</li>
          </ul>
        </div>
        <div class="keyboard-panel-calculate">
          <ul>
            <li v-for="item in keycalculate" :key="item.v" @click="insertar(item.v)">{{item.k}}</li>
          </ul>
        </div>
        <div class="keyboard-panel-rt">
          <ul>
            <li class="backspace" @click="backDown('Backspace')">
              <i class="iconfont icon_delete"></i>
            </li>
            <li class="submit" @click="keyhide()">确认</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'mathquill/build/mathquill'
import img from './img.js'
import { setTimeout, clearTimeout } from 'timers'

export default {
  name: 'math-keyboard',
  data() {
    return {
      img: img,
      mathField: null,
      mathinput: 'mathinput',
      keysym: [
        { k: 'x', v: 'x' },
        { k: 'y', v: 'y' },
        { k: 'z', v: 'z' },
        { k: '', v: '^', i: img.potencia },
        { k: '', v: '\\sqrt', i: img.raiz2 },

        { k: 'a', v: 'a' },
        { k: 'b', v: 'b' },
        { k: 'c', v: 'c' },
        { k: '', v: '/', i: img.fraccion },
        { k: '', v: '_', i: img.jiaobiao },

        { k: '', v: '\\infty', i: img.infty },
        { k: 'e', v: 'e' },
        { k: '(', v: '(' },
        { k: ')', v: ')' },
        { k: '°', v: '\\degree' },

        { k: '[', v: '[' },
        { k: ']', v: ']' },
        { k: '{', v: '{' },
        { k: '}', v: '}' },
        { k: ',', v: ',' },
      ],
      keynum: [
        { k: '1', v: '1' },
        { k: '2', v: '2' },
        { k: '3', v: '3' },
        { k: '4', v: '4' },
        { k: '5', v: '5' },
        { k: '6', v: '6' },
        { k: '7', v: '7' },
        { k: '8', v: '8' },
        { k: '9', v: '9' },
        { k: '0', v: '0' },
        { k: '.', v: '.' },
        { k: 'π', v: '\\pi' },
      ],
      keycalculate: [
        { k: '+', v: '+' },
        { k: '-', v: '-' },
        { k: '=', v: '=' },
        { k: '≠', v: '\\neq' },
        { k: '>', v: '>' },
        { k: '<', v: '<' },
        { k: '≥', v: '\\geq' },
        { k: '≤', v: '\\leq' },
      ],
      cursorx: 0,
      cursory: 0,
      timer: null,
      interval: null,
    }
  },
  props: {
    show: Boolean,
    value: String,
    bgshow: {
      type: Boolean,
      default: true,
    },
    output: String,
  },
  mounted() {
    var that = this
    var mathFieldSpan = document.getElementById(this.mathinput)

    var MQ = MathQuill.getInterface(2)
    var mathField = (that.mathField = MQ.MathField(mathFieldSpan, {
      spaceBehavesLikeTab: true,
      leftRightIntoCmdGoes: 'up',
      restrictMismatchedBrackets: true,
      sumStartsWithNEquals: true,
      supSubsRequireOperand: true,
      autoSubscriptNumerals: true,
      autoOperatorNames: 'sin COMMA',
      handlers: {
        edit: function (mathField) {
          that.$emit('update:value', mathField.latex())

          //显示光标
          const controller = mathField.__controller
          controller.cursor.show()
          controller.blurred = false
        },
      },
    }))

    that.mathField.latex(that.value)
  },
  methods: {
    insertar(valor) {
      var that = this
      if (valor.includes('^2') || valor.includes('^3')) {
        that.mathField.write(valor)
      } else if (valor == 'Backspace') {
        that.mathField.keystroke(valor)
      } else {
        that.mathField.cmd(valor)

        // 我真特么机智。。。。
        // that.mathField.keystroke("Left");
        // that.mathField.keystroke("Right");
      }
    },
    keyhide() {
      this.$emit('update:show', false)
    },
    cursorDown(event) {
      var _this = this

      _this.cursorx = event.changedTouches[0].clientX
      _this.cursory = event.changedTouches[0].clientY
    },
    cursorMove(event) {
      var x = this.cursorx - event.changedTouches[0].clientX
      var y = this.cursory - event.changedTouches[0].clientY

      if (x > 10) {
        this.mathField.keystroke('Left')
        this.cursorx = event.changedTouches[0].clientX
      }

      if (x < -10) {
        this.mathField.keystroke('Right')
        this.cursorx = event.changedTouches[0].clientX
      }

      if (y > 10) {
        this.mathField.keystroke('Up')
        this.cursory = event.changedTouches[0].clientY
      }

      if (y < -10) {
        this.mathField.keystroke('Down')
        this.cursory = event.changedTouches[0].clientY
      }
    },
    cursorUp(event) {
      var _this = this
    },
    // 改写法解决长按删除问题
    backDown(val) {
      // var _this = this
      this.mathField.keystroke('Backspace')
      // if (val == 'Backspace') {
      //   _this.timer = setTimeout(function () {
      //     _this.interval = setInterval(function () {
      //       _this.mathField.keystroke('Backspace')
      //     }, 100)
      //   }, 500)
      // }
    },
    // backUp(val) {
    //   if (val == 'Backspace') {
    //     this.mathField.keystroke('Backspace')
    //     clearTimeout(this.timer)
    //     clearInterval(this.interval)
    //   }
    // },
  },
  watch: {
    value(nval, oval) {
      if (!this.show) {
        this.mathField.latex(this.value)
      }

      var content = $('#mathinput .mq-root-block')
      content.scrollLeft(content[0].scrollWidth)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
* {
  box-sizing: content-box !important;
}
.keyboard {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: -430px;
  background-color: #d2d3d8;
  z-index: 16777271;
  transition: all 0.5s;
}
.editor {
  font-size: 30px;
}
.keyboard-div {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: -1;
}
/* .keyboard-enter-active,
.keyboard-leave-active {
  transition: all 0.5s;
}
.keyboard-enter,
.keyboard-leave-to {
  transform: translateY(340px);
} */

.keyboardup {
  bottom: 0;
}

.keyboard-output {
  width: calc(100% - 4px);
  margin: 3px 2px;
  position: relative;
  background-color: #fff;
}

.keyboard-output > span {
  display: block;
  width: calc(100vw - 48px);
  min-height: 42px;
  border: none;
  font-size: 30px;
  padding-left: 3px;
  display: flex;
  align-items: center;
}

.keyboard-output > a {
  display: block;
  text-decoration: none;
  line-height: 42px;
  width: 42px;
  text-align: center;
  background-color: #fff;
  color: #2e7bfd;
  font-size: 15px;
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
}
.keyboard-panel {
  font-size: 0;
  .keyboard-panel-default {
    padding-top: 12px;
    display: flex;
    justify-content: center;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      position: relative;
      display: inline-block;
      width: calc((100vw - 20px) / 11 - 12px);
      height: 80px;
      margin-right: 12px;
      margin-bottom: 12px;
      background: #ffffff;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;

      text-align: center;
      font-size: 26px;
      color: #000000;
      line-height: 80px;
      vertical-align: middle;
      img {
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 42px;
        position: absolute;
        z-index: 0;
        left: 50%;
      }
    }
  }

  .keyboard-panel-symbol {
    // width: 560px;
    width: calc((100vw - 20px) / 11 * 5);
    margin-right: 12px;
  }
  .keyboard-panel-num {
    width: calc((100vw - 20px) / 11 * 3);
    // width: 336px;
  }
  .keyboard-panel-calculate {
    width: calc((100vw - 20px) / 11 * 2);
    // width: 224px;
  }
  .keyboard-panel-rt {
    width: calc((100vw - 20px) / 11);
    // width: 112px;
    margin-right: -12px;
    .backspace {
      background-color: #e4e4e4;
      color: #777777;
      .icon_delete {
        font-size: 30px;
      }
    }
    .submit {
      height: 264px;
      line-height: calc((100vw - 20px) / 11);
      background-color: #00ba95;
      color: #fff;
      writing-mode: tb-rl;
      letter-spacing: 10px;
      user-select: none;
    }
  }
}

// .keyboard textarea {
//   display: none !important;
// }
// .mathview textarea {
//   display: none !important;
// }

// .mathview .mq-cursor {
//   display: none !important;
// }
// .keyboard-output .mq-root-block {
//   /* position: absolute;
//   top: 50%; */
//   /* transform: translateY(-50%); */
//   overflow: scroll;
// }
</style>